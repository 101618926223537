<template>
  <v-main>
    <v-container fluid page>
      <v-row align="center">
        <v-col cols="12">
          <h2 class="display-1 font-weight-light pt-5 pl-5">
            {{ $t('categories.title') }}
          </h2>
        </v-col>

        <v-col cols="12" v-if="error">
          <v-alert border="left" colored-border type="error" elevation="2">
            <strong v-if="code">{{ code }}</strong>
            {{ message }}
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="categories.length == 0">
          <v-alert border="left" colored-border type="info" elevation="2">{{
            $t('categories.noCategories')
          }}</v-alert>
        </v-col>
      </v-row>

      <!-- <v-row align="center" class="align-lg-stretch"> -->
      <!-- Categories cards -->

      <draggable
        v-model="categories"
        handle=".handle"
        tag="div"
        class="row align-stretch"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          class="d-flex"
          v-for="category in categories"
          :key="'category_' + category.id"
        >
          <EasyServeCategory
            :category="category"
            @category-products="products(category)"
            @category-delete="remove(category)"
            @category-edit="edit(category)"
          ></EasyServeCategory>
        </v-col>
      </draggable>

      <!-- Categories cards -->
      <!-- </v-row> -->

      <!-- Create fab button -->
      <v-fab-transition>
        <v-btn
          color="red"
          fab
          dark
          large
          bottom
          right
          ripple
          fixed
          @click="create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-container>

    <!-- Remove buttom sheet -->
    <v-bottom-sheet v-model="removeDialog">
      <v-sheet class="text-center px-3" min-height="200px" v-if="category">
        <div class="pt-6">
          ¿Estás segur@ que quieres eliminar
          <span class="font-weight-medium">
            '{{ category.name | translate($i18n) }}'</span
          >?
        </div>

        <div
          class="py-3"
          v-if="category != null && category.products_count > 0"
        >
          <div>
            Por favor, seleccione de abajo la nueva categoria para los
            productos.
          </div>

          <v-row>
            <v-col
              cols="12"
              sm="8"
              offset-sm="2"
              md="6"
              offset-md="3"
              lg="4"
              offset-lg="4"
            >
              <v-select
                class="mt-2"
                v-model="substituteCategory"
                :items="substituteCategories"
                prepend-icon="mdi-shape"
                label="Seleccione una categoría"
                item-value="id"
                :item-text="itemText"
                width="300px"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <div class="pb-5">
          <v-btn class="mt-6 mx-2" @click="removeCanceled">Cancelar</v-btn>
          <v-btn
            class="mt-6 mx-2"
            color="error darken-1"
            @click="removeConfirmed"
            :disabled="
              category != null &&
              category.products_count > 0 &&
              substituteCategory == -1
            "
            >Elminar</v-btn
          >
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <!-- Remove buttom sheet -->

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
    <!-- Loading screen -->
  </v-main>
</template>

<script>
import errors from '../mixins/errors';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import EximyrLoading from '../components/EximyrLoading';

import EasyServeCategory from './components/EasyServeCategory';

export default {
  mixins: [errors],

  components: {
    draggable,
    EximyrLoading,
    EasyServeCategory,
  },

  data: () => ({
    category: null,
    substituteCategory: -1,

    removeDialog: false,

    drag: false,
  }),

  computed: {
    ...mapGetters('categories', {
      // categories: 'categories',
      loading: 'loading',
    }),

    categories: {
      get() {
        return this.$store.state.categories.categories;
      },
      set(values) {
        this.reorder(values);
      },
    },

    substituteCategories() {
      const substituteCategories = this.categories.filter(
        category => category.id !== this.category.id
      );

      substituteCategories.unshift({
        // FIXME: Multi-Language
        name: {
          es: 'Elimina todos los productos.',
          en: 'Remove all products.',
          de: 'Entfernen Sie alle Produkte.',
          it: 'Rimuovi tutti i prodotti.',
          fr: 'Supprimer tous les produits.',
          pt: 'Remova todos os produtos.',
        },
        id: 0,
      });

      return substituteCategories;
    },

    itemText() {
      // Needed in the select for category substitution (remove category feature)
      return 'name[' + this.$i18n.locale + ']';
    },

    dragOptions() {
      return {
        animation: 200,
        group: 'categories',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  created() {
    this.load();
  },

  methods: {
    load() {
      // Create the payload
      const payload = {
        restaurant: this.$route.params.restaurant,
      };

      this.$store
        .dispatch('categories/load', payload)
        .then(() => {
          // Clear all errors
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    products(category) {
      this.$router.push({
        name: 'products',
        params: {
          restaurant: this.$route.params.restaurant,
          category: category.id,
        },
      });
    },

    // Reorder categories
    reorder(categories) {
      const payload = {
        restaurant: this.$route.params.restaurant,
        categories: categories,
      };

      this.$store
        .dispatch('categories/reorder', payload)
        .then(() => {
          // Clear all errors
          this.clearErrors();
        })
        .catch(error => {
          this.storeError(error);
        });
    },

    // Create category
    create() {
      this.$router.push({
        name: 'categories.create',
      });
    },

    // Edit categories
    edit(category) {
      // Clear all categories and move to the edit page
      this.$store.dispatch('categories/clear').then(() =>
        this.$router.push({
          name: 'categories.edit',
          params: { category: category.id },
        })
      );
    },

    // Remove category
    remove(category) {
      this.category = category;
      this.removeDialog = true;
    },
    removeCanceled() {
      this.substituteCategory = -1;
      this.category = null;
      this.removeDialog = false;
    },
    removeConfirmed() {
      const payload = {
        restaurant: this.$route.params.restaurant,
        category: this.category.id,
        substituteCategory: this.substituteCategory,
      };

      // Remove this category and reload
      this.$store
        .dispatch('categories/remove', payload)
        .then(() => {
          // Once deleted, reload all allergens in this category
          this.load();
        })
        .catch(error => {
          this.storeError(error);
        });

      this.removeCanceled();
    },
  },
};
</script>
